/* eslint-disable no-console, no-unused-vars */

import VenoBox from 'venobox';
import Parallax from 'parallax-js';
import AOS from 'aos';
import backgroundLoader from './modules/loaders/background-loader';
import intersectionObservers from './modules/intersection-observers';
import menu from './modules/menu';

menu();
AOS.init();

const scene = document.getElementById('scene');
const parallax = new Parallax(scene, {
  relativeInput: true,
  hoverOnly: true,
});

const venobox = new VenoBox({
  selector: '.grid-item',
  infinigall: true,
  share: false,
  spinColor: '#e300a2',
  spinner: 'swing',
});

const sections = document.querySelectorAll('[data-section]');

const isInFocus = section => (
  document.querySelector(`[data-section="${section}"].in-focus`) !== null
);

const manageFocus = () => {
  const {
    innerHeight,
    scrollY,
  } = window;
  const tolerance = innerHeight * 0.1;
  const offset = innerHeight - tolerance;
  for (let i = 0; i < sections.length; i++) { /* eslint-disable-line no-plusplus */
    const section = sections[i];
    const top = Math.max(section.offsetTop - offset, 0);
    const btm = (section.offsetTop + section.offsetHeight) - offset;
    if (scrollY >= top && scrollY < btm) {
      section.classList.add('in-focus');
    } else {
      section.classList.remove('in-focus');
    }
  }
};

setInterval(() => {
  manageFocus();
}, 100);

window.addEventListener('load', () => {
  setTimeout(() => {
    const body = document.querySelector('body');
    body.classList.add('site-loaded');
    backgroundLoader();
    intersectionObservers();
  }, 100);
});

/* eslint-enable no-console, no-unused-vars */
