/* eslint-disable no-console */
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

const menu = () => {
  const body = document.querySelector('body');
  const menuActiveClass = 'open';
  const menuAnimatelass = 'nav-menu-animate';
  const menuBtn = document.querySelector('.nav-menu-btn');
  const menuDiv = document.querySelector('.nav-menu');
  const menuList = menuDiv.querySelector('ul');
  const sceneNames = [];
  const sceneNavBtns = document.querySelectorAll('.nav-menu .scene-navigation-btn');

  let currentHash = '';

  for (let i = 0; i < sceneNavBtns.length; i++) { /* eslint-disable-line no-plusplus */
    sceneNames.push(sceneNavBtns[i].getAttribute('data-scene-name'));
  }

  const removeActiveClass = () => {
    menuDiv.classList.remove(menuActiveClass);
    menuBtn.classList.remove(menuActiveClass);
    body.classList.remove(menuAnimatelass);
    setTimeout(() => {
      body.classList.remove(menuActiveClass);
    }, 100);
  };

  const addActiveClass = () => {
    menuDiv.classList.add(menuActiveClass);
    menuBtn.classList.add(menuActiveClass);
    body.classList.add(menuActiveClass);
    setTimeout(() => {
      body.classList.add(menuAnimatelass);
    }, 100);
  };

  const updateMenuState = () => {
    if (body.classList.contains(menuActiveClass)) {
      removeActiveClass();
    } else {
      addActiveClass();
    }
  };

  const setActiveMenuState = (btn) => {
    const navBtn = document.querySelector('.scene-navigation-btn.active');
    if (navBtn) {
      navBtn.classList.remove('active');
    }
    btn.classList.add('active');
  };

  const getSceneOffsetPos = sceneName => (
    document.querySelector(`[data-section="${sceneName}"]`).offsetTop
  );

  menuBtn.addEventListener('click', () => {
    // Manage active classes
    // If link is the same as the active hash, still need to fire the event, close the menu
    updateMenuState();
  });

  const scrollToPosition = (pos) => {
    window.scrollTo({
      top: pos,
      behavior: 'smooth',
    });
  };

  const scrollToSection = (hash) => {
    const sceneName = hash.substr(1);
    removeActiveClass();
    if (hash === '') {
      scrollToPosition(0);
      return;
    }
    const pos = getSceneOffsetPos(sceneName);
    scrollToPosition(pos);
    const btn = document.querySelector(`.nav-menu [data-scene-name="${sceneName}"]`);
    setActiveMenuState(btn);
    currentHash = hash;
  };

  const onHashChange = () => {
    const { location } = window;
    const { hash } = location;
    scrollToSection(hash);
  };

  const onWindowResize = () => {
    if (menuList.offsetHeight > window.innerHeight) {
      menuDiv.classList.add('top-align');
    } else {
      menuDiv.classList.remove('top-align');
    }
  };

  window.addEventListener('hashchange', onHashChange, false);
  window.addEventListener('resize', onWindowResize);

  [].map.call(sceneNavBtns, btn => (
    btn.addEventListener('click', () => {
      const href = btn.getAttribute('href');
      if (href === currentHash) {
        scrollToSection(href);
      }
    })
  ));
};

export default menu;
/* eslint-enable no-console */
