/* eslint-disable no-console */
const backgroundLoader = () => {
  const assetsToLoad = document.querySelectorAll('[data-asyncload]');
  let assetLoadedCt = 0;
  let prevImg = null;

  const update = () => {
    // Remove previous image load event handler
    if (prevImg !== null) {
      prevImg.removeEventListener('load', update);
    }
    const img = assetsToLoad[assetLoadedCt];
    img.src = img.getAttribute('data-src');
    img.removeAttribute('data-asyncload');

    assetLoadedCt += 1;
    if (assetLoadedCt === assetsToLoad.length) {
      prevImg.removeEventListener('load', update);
      // console.log('background load complete');
      return;
    }
    img.addEventListener('load', update);
    prevImg = img;
  };

  if (assetsToLoad.length !== 0) {
    update();
  }
};

export default backgroundLoader;
/* eslint-enable no-console */
