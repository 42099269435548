/* eslint-disable no-console */
const callback = (entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add('in-focus');
    }
  });
};

const intersectionObservers = () => {
  const elements = document.querySelectorAll('[data-animate]');
  const options = {};

  for (let i = 0; i < elements.length; i++) { /* eslint-disable-line no-plusplus */
    const observer = new IntersectionObserver(callback, options);
    observer.observe(elements[i]);
  }
};

export default intersectionObservers;
/* eslint-enable no-console */
